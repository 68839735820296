
.container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 1em auto 3em;
    position: relative
}

.slider {
    position: relative;
    width: 95%
}

.slider__left-value,.slider__range,.slider__right-value,.slider__track {
    position: absolute
}

.slider__range,.slider__track {
    border-radius: 3px;
    height: 5px
}

.slider__track {
    background-color: #ced4da;
    width: 95%;
    z-index: 1
}

.slider__range {
    background-color: #9fe5e1;
    z-index: 2
}

.slider__left-value,.slider__right-value {
    font-size: 12px;
    margin-top: 20px
}

.slider__left-value {
    left: 6px
}

.slider__right-value {
    right: -4px
}

.thumb,.thumb::-webkit-slider-thumb {
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none
}

.thumb {
    height: 0;
    outline: none;
    pointer-events: none;
    position: absolute;
    width: 95%
}

.thumb--zindex-3 {
    z-index: 3
}

.thumb--zindex-4 {
    z-index: 4
}

.thumb--zindex-5 {
    z-index: 5
}

.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
    width: 18px
}

.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
    width: 18px
}
