.appear-from-left {
  -webkit-animation: leftToRight 0.7s linear;
  animation: leftToRight 0.7s linear;
}
.priceBar {
  position: absolute;
  bottom: 3px;
  right: 6px;
  background: #b61404;
  padding: 3px;
  height: 11%;
  width: 50%;
  color: yellow;
  font-size: 3.2vw;
  font-weight: 900;
  text-align: center;
  font-family: sans-serif;
  /* border-radius: 19px; */
  border: 3px solid #ffff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
#root {
  max-width: 380px;
  margin: auto;
  background: white;
  min-height: 100vh;
  border-left: 2px solid #e4e4e4d1;
  border-right: 2px solid #e4e4e4d1;
}
