.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.separator {
  align-items: center;
  color: gray;
  display: flex;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
}

.separator:after,
.separator:before {
  border-bottom: 2px solid #000;
  content: "";
  flex: 1 1;
}

.separator:not(:empty):before {
  margin-right: 0.25em;
}

.separator:not(:empty):after {
  margin-left: 0.25em;
}

.showbarPrice {
  position: absolute;
  bottom: 3px;
  right: 6px;
  background: #b61404;
  padding: 3px;
  height: 11%;
  width: 50%;
  color: yellow;
  font-size: 3.2vw;
  font-weight: 900;
  text-align: center;
  font-family: sans-serif;
  /* border-radius: 19px; */
  border: 3px solid #ffff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
